<template>
    <v-container v-if="show">
        <v-row no-gutters>
          <v-simple-table id="all-email-templates">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    From Name
                  </th>
                  <th class="text-left">
                    Template Name
                  </th>
                  <th class="text-left">
                    Reply To
                  </th>
                  <th class="text-left">
                    Subject
                  </th>
                  <th class="text-center">
                    Last Modified
                  </th>
                  <th class="text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in emailTemplatesData"
                  :key="item.id"
                >
                  <td @click="viewEmailTemplate(item)" class="templateFromName"><div>{{item.from_name}}</div></td>
                  <td @click="viewEmailTemplate(item)" class="templateName"><div>{{item.name}}</div></td>
                  <td @click="viewEmailTemplate(item)"><span v-if="item.reply_to">{{item.reply_to}}</span><span v-if="!item.reply_to"></span></td>
                  <td @click="viewEmailTemplate(item)" class="templateSubject"><div>{{ item.subject }}</div></td>
                  <td @click="viewEmailTemplate(item)" class="text-center">{{ getMonth(item.updatedAt) +'/'+ new Date(item.updatedAt).getDate() +'/'+ new Date(item.updatedAt).getFullYear() }}</td>
                  <td class="more-options text-center">
                    <v-icon @click="openAddEmailTemplateBox('Update', item)" :class="'disabled-'+templateActionBtnDisabled">edit</v-icon>
                    <a @click="deleteEmailTemplate(item)" :class="'disabled-'+templateActionBtnDisabled">
                      <v-progress-circular
                          indeterminate
                          color="#777BD2"
                          v-if="selectedID == item.id"
                      ></v-progress-circular>
                      <v-icon v-if="selectedID != item.id" class="deleteBtn">delete</v-icon>
                    </a>
                    <transition name="fade-transition"><confirmation-dialog v-if="dialogId == item.id" actionName="deleteEmailTemplate" primaryTxt="Delete" :item="item" @close="dialogId = ''"></confirmation-dialog></transition>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <template v-if="!emailTemplatesData.length && !gettingTemplatesInProgress">
            <p class="empty-templates-msg">No email template has been added. Get started by adding a new email template</p>
            <v-btn dark class="create-template primary elevation-0"  @click="openNewAddEmailTemplateBox('add')">Create Email Template</v-btn>
          </template>
          <template>
            <v-progress-circular
                indeterminate
                color="#777BD2"
                v-if="gettingTemplatesInProgress"
                class="progressLoader"
            ></v-progress-circular>
          </template>
        </v-row>
    </v-container>
</template>
<script>
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';

import { Storage } from 'aws-amplify';
export default {
    name: 'EmailTemplates',
    components:{
      ConfirmationDialog
    },
    data(){
        return{
          userEmail: '',
          show: false
        }
    },
    computed: {
      emailTemplatesData(){
        var x = '';
        if((this.$store.state.EmailTemplates == undefined || this.$store.state.EmailTemplates == null) && this.userEmail != ''){
            this.showEmailTemplates();
        }
        else if(this.$store.state.EmailTemplates && this.userEmail != ''){
            x = this.$store.state.EmailTemplates.data;
        }
        return x;
      },
      selectedID(){
        return this.$store.state.selectedID
      },
      templateActionBtnDisabled(){
        return this.$store.state.templateActionBtnDisabled
      },
      dialogId:{
        get(){
          return this.$store.state.dialogId;
        },
        set(value){
          this.$store.state.dialogId = value
        }
      },
      gettingTemplatesInProgress(){
        return this.$store.state.gettingTemplatesInProgress;
      },
    },
    methods: {
        showEmailTemplates(){
          this.$store.state.gettingTemplatesInProgress = true;
          this.$store.state.EmailTemplates = null;
          this.$store.dispatch('getEmailTemplates', this.userEmail);
        },
        getMonth(time){
          var month = new Date(time).getMonth() + 1;
          if(month<10){
              month = '0'+month;
          }
          return month;
        },
        openAddEmailTemplateBox(param, template){
          var _this = this;
          this.$store.state.templateID = template.id;
          this.$store.state.templateName = template.name;
          this.$store.state.templateFromName = template.from_name
          this.$store.state.templateFrom = template.sender.email;
          this.$store.state.templateSubject = template.subject;
          this.$store.state.templateBody = template.body;
          this.$store.state.templateReplyTo = template.reply_to;
          this.$store.state.emailTemplateAttachmentName = template.attachment_name;
          if(param == 'add'){
            this.$store.state.emailTemplateAttachment = [];
            this.$store.state.templateReplyTo = this.userEmail;
            this.$store.state.primary_btn_txt = 'Create';
            this.$store.state.emailTemplateHd = 'Create Email Template';
          }
          else{
            if(this.$store.state.templateReplyTo == '' || this.$store.state.templateReplyTo == undefined){
              this.$store.state.templateReplyTo = this.userEmail;
            }
            if(this.$store.state.emailTemplateAttachmentName){
              Storage.list(_this.$store.state.emailTemplateAttachmentName)
              .then(result => {
                _this.$store.state.emailTemplateAttachment = result[0];
                //eslint-disable-next-line
                _this.$store.state.emailTemplateAttachment.name = _this.$store.state.emailTemplateAttachmentName.replace(/\_@.*\./, '.');
              })
              .catch(err => console.log(err));
            }
            else{
              this.$store.state.emailTemplateAttachment = [];
            }
            this.$store.state.primary_btn_txt = 'Update';
            this.$store.state.emailTemplateHd = 'Edit Email Template';
          }
          this.$store.state.addEmailTemplateBox = true;
        },
        openNewAddEmailTemplateBox(param){
            if(param == 'add'){
              this.$store.state.templateReplyTo = this.userEmail;
              this.$store.state.primary_btn_txt = 'Add';
              this.$store.state.emailTemplateHd = 'Create Email Template';
            }
            else{
                this.$store.state.primary_btn_txt = 'Update';
                this.$store.state.emailTemplateHd = 'Edit Email Template';
            }
            this.$store.state.addEmailTemplateBox = true;
        },
        viewEmailTemplate(item){
          this.$store.state.TemplateData = item;
        },
        deleteEmailTemplate(item){
          // this.$store.state.templateActionBtnDisabled = true;
          this.$store.state.templateActionBtnDisabled = false;
          this.$store.state.dialogId = item.id;
        },
        cancel(){
          this.$store.state.dialogId = '';
          this.$store.state.templateActionBtnDisabled = false;
        },
        
    },
    mounted(){
      if(this.$store.state.user == null || this.$store.state.user == undefined){
        this.$router.push({path: '/sign-in'});
      }
      else{
        this.userEmail = this.$store.state.user.attributes.email;
        this.showEmailTemplates();
        this.show = true
      }
    }
}
</script>
<style scoped>
.more-options button:first-child, .more-options a:first-child{
  margin-right: 5px;
}
.more-options button, .more-options a{
  opacity: 0.6;
}
.more-options button:hover, .more-options a:hover{
  opacity: 1;
}
tr td{
    cursor: pointer;
}
tr td:last-child{
    cursor: auto;
}
.progressLoader.v-progress-circular{
  height: 100px !important;
  width: 100px !important;
  margin: 15px auto;
}
.empty-templates-msg{
  width: 100%;
  text-align: center;
  font-size: 20px;
  padding: 25px 0px 15px;
}
.create-template{
  margin: auto;
  background-color: #1F95EE !important;
  padding: 25px 30px;
}
.templateName> div{
  max-width: 350px;
  overflow: hidden;
  overflow-wrap: break-word;
}
.templateSubject>div{
  max-width: 200px;
  overflow: hidden;
  overflow-wrap: break-word;
}
a.disabled-false{
  pointer-events: auto;
}
a.disabled-true{
  pointer-events: none;
}
</style>